export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "sprunkle",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://game.sprunki.com/game.sprunki.html",
    domain: "sprunkle.org",
    gaId: "G-9ENB0S86XV",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
